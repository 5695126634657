import React from 'react'
import { clsx } from 'clsx'
import { HandThumbDownIcon, HandThumbUpIcon, UserIcon } from '@heroicons/react/24/solid'
import { getRelativeTimeString } from '../../../../components/common/utils/dateDisplay'

interface MessageBaseProps {
  actor: string
  agentFirstName?: string
  agentImage?: React.ReactNode
  createdAt: string
  children: React.ReactNode
  showVoting?: boolean
  showName?: boolean
  onThumbsUp?: () => void
  onThumbsDown?: () => void
  isThumbsUp?: boolean | null
}

const MessageBase: React.FC<MessageBaseProps> = ({
  actor,
  agentFirstName,
  agentImage,
  createdAt,
  children,
  showVoting = false,
  showName = true,
  onThumbsUp,
  onThumbsDown,
  isThumbsUp,
}) => {
  const isUser = actor.toLowerCase() === 'user'

  return (
    <div className={clsx('flex flex-col', isUser ? 'items-end' : 'items-start')}>
      {showName && (
        <div className="flex items-center space-x-2 mb-4">
          {!isUser && agentImage}
          <span className="text-sm font-semibold text-gray-500">
            {isUser ? 'you' : agentFirstName}
          </span>
          {isUser && <UserIcon className="h-5 w-5 text-gray-400" />}
        </div>
      )}
      <div
        className={`px-4 py-2 rounded-lg ${
          isUser ? 'bg-[rgb(237,243,253)] text-gray-800' : 'bg-gray-100 text-gray-800'
        }`}
      >
        {children}
      </div>
      <div className="flex items-center space-x-4 mt-1">
        <span className="text-xs text-gray-500">{getRelativeTimeString(createdAt)}</span>
        {!isUser && showVoting && (
          <>
            <HandThumbUpIcon
              onClick={onThumbsUp}
              className={clsx(
                isThumbsUp === true
                  ? 'text-green-600'
                  : 'text-gray-400 hover:text-gray-500 cursor-pointer',
                'h-4 w-4'
              )}
            />
            <HandThumbDownIcon
              onClick={onThumbsDown}
              className={clsx(
                isThumbsUp === false
                  ? 'text-red-600'
                  : 'text-gray-400 hover:text-gray-500 cursor-pointer',
                'h-4 w-4'
              )}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default MessageBase
