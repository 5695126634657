import React, { useEffect, useState } from 'react'
import AgentHeader from '../OneAgent/AgentHeader'
import Chat from './Chat'
import { useHistory, useLocation } from 'react-router-dom'
import {
  ActorEnum,
  AgentFieldsFragment,
  AgentQuery,
  AgentQueryVariables,
  CompanyAgentFieldsFragment,
  GetMessageHistoriesQuery,
  GetMessageHistoriesQueryVariables,
  GetStepHistoriesQuery,
  GetStepHistoriesQueryVariables,
  SendAgentMessageMutation,
  SendAgentMessageMutationVariables,
} from '../../../../graphql'
import { useMutation, useQuery } from '@apollo/client'
import { GET_AGENT_QUERY, SEND_AGENT_MESSAGE } from '../../../graphql/queries/agent'
import { GET_MESSAGE_HISTORIES, GET_STEP_HISTORIES } from '../../../graphql/queries/message_thread'
import { chatActions } from '../../../slices/chatSlice'
import Spin from '../../../../common/ui/Spin'
import { useDispatch } from 'react-redux'
import { useChatSelector } from '../../../store'

interface AgentChatProps {
  agent: AgentFieldsFragment | CompanyAgentFieldsFragment
  threadUuidFromUrl: string
  queryFromUrl: string
}

const AgentChat = ({ agent, threadUuidFromUrl, queryFromUrl }: AgentChatProps) => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  const [activeChatId, setActiveChatId] = useState<string | null>(null)

  const activeChat = useChatSelector(activeChatId)

  const { threadUuid, query, chatId } = activeChat || {}

  const [sendMessage] = useMutation<SendAgentMessageMutation, SendAgentMessageMutationVariables>(
    SEND_AGENT_MESSAGE
  )

  useEffect(() => {
    // Create a new chat when the component mounts
    const action = chatActions.createChat()
    const chatId = action.payload.chatId

    dispatch(action)
    setActiveChatId(action.payload.chatId)

    dispatch(chatActions.setAgent({ chatId, agent }))
    dispatch(chatActions.setAgentUuid({ chatId, agentUuid: agent.uuid }))

    // we have a thread uuid in the url, but not in the state
    if (threadUuidFromUrl) {
      dispatch(chatActions.setThreadUuid({ chatId, threadUuid: threadUuidFromUrl }))
    }

    // we have a query in the url, but not in the state
    if (queryFromUrl && queryFromUrl.trim().length > 0 && !threadUuidFromUrl) {
      dispatch(chatActions.setQuery({ chatId, query: queryFromUrl }))
    }

    // Cleanup function to remove the chat when the component unmounts
    return () => {
      if (chatId) {
        dispatch(chatActions.removeChat({ chatId }))
      }
    }
  }, [])

  const { data: messageHistoryData, refetch: refetchMessageHistoryData } = useQuery<
    GetMessageHistoriesQuery,
    GetMessageHistoriesQueryVariables
  >(GET_MESSAGE_HISTORIES, {
    // Only execute the query if the thread_uuid exists.
    skip: !threadUuid,
    variables: {
      uuid: threadUuid,
    },
    fetchPolicy: 'network-only',
  })

  const { data: stepHistoryData, refetch: refetchStepHistoryData } = useQuery<
    GetStepHistoriesQuery,
    GetStepHistoriesQueryVariables
  >(GET_STEP_HISTORIES, {
    // Only execute the query if the thread_uuid exists.
    skip: !threadUuid,
    variables: {
      uuid: threadUuid,
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!stepHistoryData?.getStepHistories) {
      return
    }

    dispatch(chatActions.setSteps({ chatId, steps: stepHistoryData.getStepHistories }))
  }, [stepHistoryData])

  useEffect(() => {
    const messageHistory = messageHistoryData?.getMessageHistories || []
    if (messageHistory.length === 0) {
      return
    }

    dispatch(chatActions.setMessages({ chatId, messages: messageHistory }))
  }, [messageHistoryData])

  // monitor the queue and send messages
  useEffect(() => {
    // Define an asynchronous function within the useEffect
    const processMessageQueue = async () => {
      if (!query || query.trim().length === 0) {
        return
      }

      dispatch(chatActions.setIsQuerying({ chatId, isQuerying: true }))

      dispatch(
        chatActions.addMessage({
          chatId,
          message: {
            message: [{ type: 'text', value: query }],
            actor: ActorEnum.User,
            createdAt: new Date().toISOString(),
            createdAtMilli: new Date().getTime(),
            id: null,
            updatedAt: new Date().toISOString(),
            uuid: '',
          },
        })
      )

      try {
        const { data } = await sendMessage({
          variables: {
            agentUuid: agent.uuid,
            chatId: chatId,
            enableStreaming: true,
            threadUuid,
            message: query,
          },
        })

        if (data.sendAgentMessage.errors.length) {
          window.toastr.error('Something went wrong. Please try again later.')
        }

        const newThreadUuid = data.sendAgentMessage.threadUuid
        if (newThreadUuid && newThreadUuid !== threadUuid) {
          dispatch(
            chatActions.setThreadUuid({ chatId, threadUuid: data.sendAgentMessage.threadUuid })
          )

          // update the url
          const searchParams = new URLSearchParams(location.search)
          searchParams.set('thread_uuid', newThreadUuid)
          history.replace({ search: searchParams.toString() })
        }

        await refetchMessageHistoryData()
        await refetchStepHistoryData()
      } catch (error) {
        console.error('Failed to process message:', error)
        // Handle any other errors here
      } finally {
        dispatch(chatActions.setQuery({ chatId, query: '' }))
        dispatch(chatActions.setIsQuerying({ chatId, isQuerying: false }))
      }
    }

    // Call the async function
    processMessageQueue()
  }, [query])

  if (!agent) {
    return <></>
  }

  return <Chat chatId={chatId} />
}

const OneAgentChat = ({ uuid }: { uuid: string }) => {
  const history = useHistory()

  // get the agent data
  const { data, loading } = useQuery<AgentQuery, AgentQueryVariables>(GET_AGENT_QUERY, {
    variables: { uuid },
    fetchPolicy: 'network-only',
  })

  const agent = data?.agent

  const searchParams = new URLSearchParams(location.search)
  const threadUuidFromUrl = searchParams.get('thread_uuid')
  const queryFromUrl = searchParams.get('query')

  const threadUuid = threadUuidFromUrl || ''
  const query = (!threadUuid && queryFromUrl) || ''

  if (loading) {
    return <Spin />
  }

  if (!agent) {
    window.toastr.error('Agent not found')
    history.push('/agents')
    return <></>
  }

  // Use threadUuid and query as part of the key
  const chatKey = `${agent.uuid}-${threadUuid}-${query}`

  return (
    <div>
      <AgentHeader agent={agent} />
      <AgentChat key={chatKey} agent={agent} threadUuidFromUrl={threadUuid} queryFromUrl={query} />
    </div>
  )
}

export default OneAgentChat
