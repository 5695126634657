import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { RootState } from '../../store'
import { useSelector } from 'react-redux'
import Sidebar from './Sidebar'
import clsx from 'clsx'

type SidebarLayoutProps = {
  children: React.ReactNode
  logoutPath: string
  csrfToken: string
  isImpersonating: boolean
}
const SidebarLayout = ({
  logoutPath,
  csrfToken,
  isImpersonating,
  children,
}: SidebarLayoutProps) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const { user } = useSelector((state: RootState) => state.app)
  const { isSidebarExpanded } = useSelector((state: RootState) => state.layoutSettings)

  if (!user || !user?.id) {
    return null
  }

  return (
    <>
      <div className="flex flex-col relative z-0 w-full h-screen">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex-grow flex-col gap-y-5 bg-gray-900 px-6 pb-4 ring-1 ring-white/10">
                    <Sidebar
                      logoutPath={logoutPath}
                      csrfToken={csrfToken}
                      isMinimizeable={false}
                      isImpersonating={isImpersonating}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div
          className={clsx(
            'hidden lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:flex-col ',
            'transition-all duration-300 ease-in-out',
            isSidebarExpanded ? 'w-64' : 'w-20'
          )}
        >
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-grow flex-col gap-y-5 bg-gray-900 px-6 pb-4">
            <Sidebar
              logoutPath={logoutPath}
              csrfToken={csrfToken}
              isMinimizeable={true}
              isImpersonating={isImpersonating}
            />
          </div>
        </div>

        <div
          className={clsx(
            'h-full flex flex-col',
            'transition-all duration-300 ease-in-out',
            isSidebarExpanded ? 'lg:ml-64' : 'lg:ml-20'
          )}
        >
          <div className="lg:hidden sticky top-0 z-10 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <main className="h-screen flex flex-col">
            <div className={clsx('flex-grow', 'pt-4 px-4 sm:px-6 lg:px-8 lg:pt-10')}>
              {children}
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default SidebarLayout
