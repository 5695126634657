import React, { Fragment } from 'react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { Dialog, Transition } from '@headlessui/react'
import { GET_MESSAGE_THREADS } from '../../../graphql/queries/message_thread'
import { useQuery } from '@apollo/client'
import {
  GetMessageThreadsQuery,
  GetMessageThreadsQueryVariables,
} from 'app/javascript/components/graphql'
import { ChatBubbleLeftIcon } from '@heroicons/react/24/outline'
import Spin from '../../../../../components/common/ui/Spin'
import { useHistory } from 'react-router-dom'
import {
  getRelativeTimeString,
  getDateCategory,
} from '../../../../../components/common/utils/dateDisplay'

interface ThreadHistoryProps {
  agentUuid: string
  isOpen: boolean
  setClose: () => void
}

const ThreadHistory = ({ agentUuid, isOpen, setClose }: ThreadHistoryProps) => {
  const history = useHistory()

  const { data, loading, refetch } = useQuery<
    GetMessageThreadsQuery,
    GetMessageThreadsQueryVariables
  >(GET_MESSAGE_THREADS, {
    variables: {
      agentUuid,
    },
    fetchPolicy: 'network-only',
  })

  React.useEffect(() => {
    if (isOpen) {
      refetch()
    }
  }, [isOpen])

  const handleThreadClick = (threadUuid: string) => {
    history.push(`/agent/${agentUuid}/chat?thread_uuid=${threadUuid}`)
    setClose()
  }

  const messageThreads = data?.getMessageThreads || []
  let lastCategory: string = null
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="bg-gray-700 px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-white">
                          Threads
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-gray-700 text-gray-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={setClose}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-gray-300">
                          These are the previous conversations you&apos;ve had with FlowMo.
                        </p>
                      </div>
                    </div>
                    <div className="relative flex-1 px-4 space-y-4 py-6 sm:px-6">
                      {messageThreads.length === 0 && (
                        <div className="flex items-center justify-center">
                          <div className="text-sm text-gray-500">No threads yet.</div>
                        </div>
                      )}
                      {loading && <Spin />}

                      {!loading &&
                        messageThreads.map((thread) => {
                          const currentCategory = getDateCategory(thread.createdAt)
                          const showDivider = lastCategory !== currentCategory
                          lastCategory = currentCategory

                          return (
                            <React.Fragment key={thread.uuid}>
                              {showDivider && <div className="divider my-2">{currentCategory}</div>}
                              <div
                                className="flex flex-row items-center justify-between p-2 rounded-xl cursor-pointer hover:bg-gray-200"
                                onClick={() => handleThreadClick(thread.uuid)}
                              >
                                <div className="text-gray-600 flex flex-row space-x-2 justify-start">
                                  <ChatBubbleLeftIcon className="h-6 w-6" />
                                  <span className="text-sm">
                                    New Chat ({getRelativeTimeString(thread.createdAt)})
                                  </span>
                                </div>
                              </div>
                            </React.Fragment>
                          )
                        })}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
export default ThreadHistory
