import React, { useEffect, useRef, useState } from 'react'
import ThreeDots from '../../../../components/common/ui/ThreeDots'
import Message from './Message'

import { createConsumer } from '@rails/actioncable'
import {
  ActorEnum,
  AgentFieldsFragment,
  CompanyAgentFieldsFragment,
  StepHistoryFieldsFragment,
  StepUnion,
} from '../../../../components/graphql'
import RunStep from '../../pages/app/OneAgentChat/RunStep'

interface MessageStreamProps {
  chatId: string
  agent: AgentFieldsFragment | CompanyAgentFieldsFragment
  showName?: boolean
}

const MessageStream = ({ chatId, agent, showName = true }: MessageStreamProps) => {
  const divRef = useRef<HTMLDivElement>(null)
  const [message, setMessage] = useState<string>('')
  const [status, setStatus] = useState<string>('')
  const [toolCalls, setToolCalls] = useState<StepHistoryFieldsFragment[]>([])
  const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:'
  const host = window.location.hostname
  const port = window.location.port ? `:${window.location.port}` : ''

  const cableURL = `${protocol}//${host}${port}/cable`

  useEffect(() => {
    const cable = createConsumer(cableURL)

    const subscription = cable.subscriptions.create(
      { channel: 'MessageStreamChannel', chat_id: chatId },
      {
        received(data) {
          if (data.message_type === 'chat_status') {
            setStatus(data.message_body['status'])
          } else if (data.message_type === 'chat_message_received') {
            const messageIncrement = data.message_body['response_message']
            setMessage((prevMessage) => prevMessage + messageIncrement)
          } else if (data.message_type == 'chat_tool_calls') {
            const steps: StepUnion[] = data.message_body.map((toolCall: any) => {
              const { name, description } = toolCall
              return {
                name,
                description,
              }
            })

            const fakeToolCall = {
              id: 0,
              uuid: '',
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
              createdAtMilli: new Date().getTime(),
              actor: ActorEnum.System,
              step: steps,
            }

            setToolCalls((prevToolCalls) => [...prevToolCalls, fakeToolCall])
          }
        },
      }
    )

    return () => {
      subscription.unsubscribe()
    }
  }, [chatId])

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [divRef, message, toolCalls])

  return (
    <>
      {toolCalls.map((toolCall, index) => (
        <RunStep key={index} agent={agent} step={toolCall} showName={showName && index == 0} />
      ))}
      {message && (
        <Message
          message={{
            message: [{ type: 'text', value: message }],
            actor: ActorEnum.System,
            createdAt: new Date().toISOString(),
            uuid: '',
            createdAtMilli: new Date().getTime(),
            id: null,
            updatedAt: new Date().toISOString(),
          }}
          showName={showName && toolCalls.length == 0}
          showVoting={false}
          agent={agent}
        />
      )}
      <div className="flex flex-row space-x-2 text-gray-300">
        <ThreeDots />
        <div className="">{status}</div>
      </div>
      <div ref={divRef}></div>
    </>
  )
}

export default MessageStream
